import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { SectionContainer, TransitionalCTA } from "@italymondo/core-ui";
import { PageList } from "../../components";

export const LinkWrapper = styled(SectionContainer)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.rowHCentered};
    padding-top: 0px !important;
  `}
`;

export const StyledPageList = styled(PageList)`
  padding-top: 0px !important;
`;

export const StyledTransitionalCTA = styled(TransitionalCTA)`
  padding-top: 0px !important;
`;
