import React from "react";
import {
  bookACallIFrame,
  Button,
  getPathname,
  Hero,
  Link,
} from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO, StreamfieldBody } from "../../components";
import { CareerTemplateQuery } from "./index.generated";
import { LinkWrapper, StyledPageList, StyledTransitionalCTA } from "./styles";

type PpCareerPage = {
  data: CareerTemplateQuery;
  location?: {
    pathname?: string;
  };
};

export default function CareerPage({
  data: {
    cms: { site },
  },
  location,
}: PpCareerPage): React.ReactElement | null {
  if (!site?.page) {
    return <Layout />;
  }

  const returnToCareersPageText = "Return to careers page";
  const careerIndexPageUrl = site.careerIndexPage[0].url || "/";

  return (
    <Layout>
      <SEO
        description={site.page?.listingSummary || ""}
        image={site.page?.listingImage?.url}
        location={location}
        title={site.page?.listingTitle || ""}
      />
      <Hero
        description={site.page.heroDescription || ""}
        headline={site.page.heroHeadline || ""}
        heroType={site.page.heroType}
        imageSrc={site.page.heroImage?.urlSharp || ""}
        primaryButton={
          site.page?.heroPrimaryButton
            ? {
                title: site.page?.heroPrimaryButton?.title,
                url: getPathname(site.page?.heroPrimaryButton?.page?.url),
              }
            : null
        }
        secondaryButton={
          site.page?.heroSecondaryButton
            ? {
                title: site.page?.heroSecondaryButton?.title,
                url: getPathname(site.page?.heroSecondaryButton?.page?.url),
              }
            : null
        }
      />
      <StreamfieldBody blocks={site.page.body} />
      <StyledTransitionalCTA
        action={{
          __typename: site.page.transitionalCta?.action[0]?.__typename,
          page: {
            url: `${careerIndexPageUrl}apply`,
          },
        }}
        buttonText={site.page.transitionalCta.buttonText}
        description={site.page.transitionalCta?.description || ""}
        popupContent={bookACallIFrame}
        shadowImage={site.page.transitionalCta?.shadowImage?.url || ""}
        title={site.page.transitionalCta?.title || ""}
      />
      <StyledPageList
        listData={site.page.careerPaths.map(({ careerPath }) => ({
          ...careerPath,
        }))}
        title={site.page.additionalCareerPathsTitle || ""}
      />
      <LinkWrapper>
        <Link to={getPathname(site.careerIndexPage[0].url)}>
          <Button color="primary" variant="contained">
            {returnToCareersPageText}
          </Button>
        </Link>
      </LinkWrapper>
    </Layout>
  );
}

export const query = graphql`
  query CareerTemplate($slug: String) {
    cms {
      site(hostname: "www.italymondo.com") {
        careerIndexPage: pages(
          contentType: "italymondo_about.IMCareerIndexPage"
        ) {
          ... on CMS_IMCareerIndexPage {
            url
          }
        }
        page(slug: $slug) {
          ... on CMS_IMCareerPage {
            __typename
            additionalCareerPathsTitle
            body {
              ... on CMS_RichTextBlock {
                __typename
                value
              }
              ... on CMS_ImageBlock {
                __typename
                image {
                  alt
                  url
                }
              }
              ... on CMS_GalleryImageBlock {
                __typename
                images {
                  image {
                    alt
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
              ... on CMS_ShadowHeaderParagraphBlock {
                __typename
                paragraph
                shadowHeader
              }
              ... on CMS_SignatureBlock {
                __typename
                signature {
                  url
                }
              }
            }
            careerPaths {
              careerPath {
                listingLinkText
                listingTitle
                location
                type
                url
              }
            }
            heroCtaText
            heroDescription
            heroHeadline
            heroImage {
              alt
              url
              urlSharp {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            heroPrimaryButton {
              page {
                url
              }
              title
            }
            heroSecondaryButton {
              page {
                url
              }
              title
            }
            heroType
            listingImage {
              url
            }
            listingSummary
            listingTitle
            location
            transitionalCta {
              action {
                ... on CMS_CollectEmailBlock {
                  __typename
                  collectEmail
                }
                ... on CMS_LinkPageBlock {
                  __typename
                  page {
                    url
                  }
                }
                ... on CMS_ShowPopupBlock {
                  __typename
                  description
                  title
                }
              }
              buttonText
              description
              shadowImage {
                url
              }
              title
            }
            type
          }
        }
      }
    }
  }
`;
